<template>
  <svg
    class="svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="iconColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 0C3.576 0 0 3.576 0 8C0 12.424 3.576 16 8 16C12.4245 16 16 12.424 16 8C16 3.576 12.4245 0 8 0ZM12 10.872L10.8725 12L8 9.128L5.12747 12L4 10.872L6.87253 8L4 5.128L5.12747 4L8 6.872L10.8725 4L12 5.128L9.12747 8L12 10.872Z"
    />
  </svg>
</template>

<script>
export default {
  data () {
    return {
      iconColor: {
        type: String,
        default: 'primary'
      }
    }
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  created () {
    switch (this.color) {
      case 'primary':
        return (this.iconColor = 'primary')
      case 'white':
        return (this.iconColor = 'white')
      case 'grey':
        return (this.iconColor = 'grey')
      case 'black':
        return (this.iconColor = 'black')
    }
  }
}
</script>
<style lang = "scss" scoped >
 .primary {
   fill: var(--primary-theme)
 }
 .white {
   fill: $white
 }
 .grey {
   fill: $buttonDisabledColor
 }
 .black {
   fill: $dark
 }

</style>
